
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { DecimalColumnConfig } from "@/components/controls/catalogueGrid/columns/decimalColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import transportBoxCarCreateEditDialog from "./TransportBoxCarCreateEditDialog.vue";
import transportBoxCarInfoDialog from "@/components/info/TransportBoxCarInfoDialog.vue";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    //панель выбора страницы
    showPageSizeSelector: { required: false, default: true },
    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
  },
  computed: {
    transportBoxCarCreateEditDialog(): any {
      return transportBoxCarCreateEditDialog;
    },
    transportBoxCarInfoDialog(): any {
      return transportBoxCarInfoDialog;
    },
  },
  data() {
    return {
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowClearFilter: true,
        allowDeleteMultipleRows: false,
        showInfo: true,
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      columns: [
        new IntegerColumnConfig({
          dataField: "id",
          caption: "Код",
          readOnly: true,
          editVisible: false,
          format: ""
        }),
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
          validationRules: [requiredRule],
        }),
        new IntegerColumnConfig({
          dataField: "length",
          caption: "Длина (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "width",
          caption: "Ширина (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "height",
          caption: "Высота (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "centerHeight",
          caption: "Высота в центре (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "doorWidth",
          caption: "Ширина дверного проема (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "doorThickness",
          caption: "Толщина дверного проема (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "doorHeight",
          caption: "Высота дверного проема (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new DecimalColumnConfig({
          dataField: "weight",
          caption: "Вес порожнего транспортного средства (кг)",
          readOnly: true,
          decimalPoints: 2,
          editVisible: false,
        }),
        new DecimalColumnConfig({
          dataField: "loadCapacity",
          caption: "Грузоподъемность (кг)",
          readOnly: true,
          decimalPoints: 2,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "heightCenterMass",
          caption: "Высота ЦТ порожнего транспортного средства (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "heightOverLevelRailHead",
          caption: "Высота уровня настила пола (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new TextColumnConfig({
          dataField: "displacementDescription",
          caption: "Смещение",
        }),
        new TextColumnConfig({
          dataField: "gapDescription",
          caption: "Отступы (мм)",
        }),
      ],
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "800px",
        heightScroll: !this.selectMode ? "calc(100vh - 190px)" : "400px",
        horizontalScroll: true,
      }),
      selectionConfig: new TableSelectionConfig({
        mode: "single",
      }),
      pageSizes: [10, 20, 50, 100],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }
  },
});
